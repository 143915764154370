import React from "react";

import "../../styles/main.scss";

import Header from "./Header";
import Footer from "./Footer";

import Container from "react-bootstrap/Container";
import SEO from "../components/Seo";
import Breadcrumb from "../components/Breadcrumb";
import TrackingCodes from "../components/TrackingCodes";
import CookieConsent from "../components/CookieConsent";

/**
 * Basic Layout Component. Uses page Context to display Header, Main and Footer Components.
 * Implements Breadcrumb logic.
 */
const Layout = props => {
  const translations = props.context.translations;
  const slug = props.context.slug;
  const currentLanguage = props.context.currentLanguage;
  const breadcrumbs = props.context.breadcrumbs;
  const homepagePath = props.context.langHomepagePath;
  const mainClassName = props.className;
  const menus = props.context.menus;
  const logo = props.context.logo;
  const footerLogo = props.context.footerLogo;
  const useContainer = props.useContainer;
  const showProgressbar = props.showProgressbar;
  const progressBarTarget = props.progressBarTarget;
  const signUpLink = props.context.signUpLink;
  const stringTranslations = props.context.stringTranslations;
  const pagePath = props.context.pagePath;
  const location = props.location;
  const cookieTranslations = props.context.cookieTranslations;

  return (
    <>
      <TrackingCodes />
      <SEO {...props} />
      <Header
        translations={translations}
        slug={slug}
        homepage={homepagePath}
        language={currentLanguage}
        logo={logo}
        menu={menus.headerMenu && menus.headerMenu.node.items}
        showProgressbar={showProgressbar}
        progressBarTarget={progressBarTarget}
        searchPlaceholder={stringTranslations["Search"]}
        pagePath={pagePath}
        location={location}
      />
      <main className={mainClassName}>
        <Breadcrumb crumbs={breadcrumbs} />
        {useContainer ? (
          <Container>{props.children}</Container>
        ) : (
          <>{props.children}</>
        )}
      </main>
      <Footer menu={menus.footerMenu} signUpLink={signUpLink} logo={footerLogo} stringTranslations={stringTranslations} />
      <CookieConsent
        cookieName="matomo_consent"
        acceptBtnText={cookieTranslations.accept}
        declineBtnText={cookieTranslations.deny}
        infoLink={cookieTranslations.infoLink}
        moreText={cookieTranslations.moreInfo}
      >
        {cookieTranslations?.body}
      </CookieConsent>
    </>
  );
};

Layout.defaultProps = {
  useContainer: true
};

export default Layout;
